import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoopIcon from '@mui/icons-material/Loop';
import { Box, Button, Typography } from '@mui/material';
import { InvalidScanModal, LoadingScan, ManuallyEntry, OfflineScanning, SuccessfulScan } from 'components/Modals';
import QRReader from 'components/QRReader/QRReader';
import { AccessModals } from 'enums/access';
import useServerDown from 'hooks/useServerDown';
import useUser from 'hooks/useUser';
import { enqueueSnackbarError, enqueueSnackbarSuccess } from 'lib/helpers';
import { OfflineScan, saveEntry, syncScans, verifyCodeDateRangeOnEntry } from 'lib/models/access';
// import { qrReaderEndKeys } from 'lib/qrEnterStrings';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// import useStoreSelector from 'store/useStoreSelector';

function EntryScanner() {
	const { t } = useTranslation();
	const isDown = useServerDown();
	const { user } = useUser();
	const [scanQueue, setScanQueue] = useState<OfflineScan[]>(JSON.parse(localStorage.getItem('scanQueue') || '[]'));

	const found = useRef('');

	const [access, setAccess] = useState<Access | undefined>();
	const [openModal, setOpenModal] = useState<string | null>(null);
	const [flash, setFlash] = useState<string | null>(null);
	const [code, setCode] = useState<string>('');
	const [offlineResults, setOfflineResults] = useState([]);
	const [activeCamera, setActiveCamera] = useState(false);
	const [isEnterPressed, setIsEnterPressed] = useState(false);

	const [offlineSuccess, setOfflineSuccess] = useState<string[]>([]);
	const [offlineErrors, setOfflineErrors] = useState<{ code: string; message: string; type: string }[]>([]);

	const [invalidScanError, setInvalidScanError] = useState<'alreadyUsed' | 'outOfDate' | 'fullSlots'>();

	const [isOut, setIsOut] = useState<'hour' | 'date'>();

	const [debugCode, setDebugCode] = useState('');

	const closeAll = () => {
		setOpenModal(null);
		setCode('');
		setDebugCode('');
		found.current = '';
		setAccess(undefined);
		setInvalidScanError(undefined);
		setIsOut(undefined);
	};

	const handleFlash = (color: string) => {
		setFlash(color);

		setTimeout(() => {
			setFlash(null);
		}, 500);
	};

	const verifyEntry = useCallback(
		async (code: string, serverIsDown: boolean) => {
			setCode(code);
			setDebugCode(code);
			if (!!code && !found.current) {
				setOpenModal('LoadingScan');
				found.current = code;
				const response = await verifyCodeDateRangeOnEntry(code);

				if (typeof response === 'string') {
					enqueueSnackbarError(t(`${response}`));
					handleFlash('red');
					setOpenModal('');
					setTimeout(() => {
						closeAll();
					}, 1000);
					return;
				}

				if ('offline' in response || serverIsDown) {
					return setOpenModal(AccessModals.OfflineScanning);
				}

				const { access, outOfDate, alreadyUsed, fullSlots, outOfHour, canAccessAfterHour } = response;

				setAccess(access);

				if (alreadyUsed) {
					setInvalidScanError('alreadyUsed');
					setOpenModal(AccessModals.InvalidScan);
					enqueueSnackbarError('QR ya utilizado');
					return handleFlash('red');
				}
				if (fullSlots) {
					setInvalidScanError('fullSlots');
					setOpenModal(AccessModals.InvalidScan);
					enqueueSnackbarError(`Máximo de vehículos alcanzado (${access.vehiclesInside}/${access.slots})`);
					return handleFlash('red');
				}
				if (outOfDate || (outOfHour && !canAccessAfterHour)) {
					setInvalidScanError('outOfDate');
					setOpenModal(AccessModals.InvalidScan);
					enqueueSnackbarError('QR fuera de fecha');
					return handleFlash('red');
				}
				if (outOfHour && canAccessAfterHour) {
					setIsOut('hour');
					handleFlash('yellow');
					return setOpenModal(AccessModals.SuccessfulScan);
				}

				handleFlash('green');
				return setOpenModal(AccessModals.SuccessfulScan);
			}
		},
		[t]
	);

	const onSaveEntry = async ({ code, plate }: { code?: string; plate?: string }) => {
		try {
			if (!code) {
				throw new Error('Missing access code');
			}
			await saveEntry({ code, plate });
			if (!access) {
				handleFlash('green');
			}
			enqueueSnackbarSuccess(t('access:entrySuccess'));
		} catch (error) {
			handleFlash('red');
			enqueueSnackbarError(error);
		}
		closeAll();
	};

	const modalProps = {
		open: openModal,
		handleClose: closeAll,
		access,
	};

	const onSaveOfflineEntry = async (plate?: string) => {
		if (user) {
			const offlineQueue = scanQueue;
			const userId = user._id;
			const newScan: OfflineScan = {
				code,
				plate,
				user: userId,
				date: new Date().toISOString(),
				type: 'entry',
			};
			offlineQueue.push(newScan);
			localStorage.setItem('scanQueue', JSON.stringify(offlineQueue));
			setScanQueue(offlineQueue);
		}
		closeAll();
	};

	const modals: Record<string, React.ReactNode> = {
		OfflineScanning: (
			<OfflineScanning
				{...modalProps}
				code={code}
				route={'entry'}
				result={offlineResults}
				setResult={setOfflineResults}
				onSave={onSaveOfflineEntry}
				offlineErrors={offlineErrors}
				offlineSuccess={offlineSuccess}
			/>
		),
		ManuallyEntry: <ManuallyEntry {...modalProps} onVerify={verifyEntry} isDown={isDown} />,
		SuccessfulScan: <SuccessfulScan {...modalProps} onSave={onSaveEntry} isEntry isOut={isOut} />,
		InvalidScan: <InvalidScanModal {...modalProps} errorType={invalidScanError} />,
		LoadingScan: <LoadingScan open={openModal} code={debugCode || code} />,
	};

	const syncStoredScan = async () => {
		const { errors, successScans } = await syncScans();
		setOpenModal(AccessModals.OfflineScanning);
		setOfflineErrors(errors);
		setOfflineSuccess(successScans);
	};

	useEffect(() => {
		const handleKeyPress = async (event: KeyboardEvent) => {
			const char = event.key;
			if (!activeCamera && !openModal) {
				if (/^[A-Z0-9]$/.test(char)) {
					setCode((prev) => (prev + event.key.toUpperCase()).slice(-8));
				}
				if (char === 'Enter' && code.length === 8) {
					await verifyEntry(code, isDown);
				}
			}
		};

		window.addEventListener('keydown', handleKeyPress);
		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [code, openModal, isDown, verifyEntry, activeCamera]);

	return (
		<>
			<Button
				size="medium"
				variant="text"
				component={Link}
				to="/"
				style={{ position: 'absolute', zIndex: 1400, left: '.5rem', top: '.5rem' }}
			>
				<ArrowBackIcon />
				<Typography sx={{ marginLeft: '0.5rem' }}>{t(`access:scan_entry`)}</Typography>
			</Button>
			{!isDown && scanQueue && scanQueue.length > 0 && (
				<Button
					variant="contained"
					onClick={syncStoredScan}
					color="primary"
					sx={{ position: 'absolute', right: '.5rem', top: '.5rem', zIndex: 50 }}
				>
					<LoopIcon />
					{t('access:sync')}
				</Button>
			)}

			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100vw',
					height: '100vh',
					zIndex: 20,
					backgroundColor: flash || 'none',
					opacity: flash === null ? 0 : 0.8,
					pointerEvents: 'none',
					transition: 'opacity 0.4s ease-in-out',
					overflow: 'hidden',
				}}
			></div>
			{activeCamera ? (
				<QRReader
					onScan={verifyEntry}
					setOpenModal={setOpenModal}
					modalName={AccessModals.ManuallyEntry}
					routeName="scan_entry"
				/>
			) : (
				<div
					style={{
						padding: 0,
						margin: 0,
						width: '100vw',
						height: '100vh',
						overflow: 'hidden',
						backgroundColor: 'black',
						color: 'white',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{!openModal && (
						<>
							<Box
								component="form"
								onSubmit={async (e) => {
									e.preventDefault();
									await verifyEntry(debugCode, isDown);
								}}
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: 2,
									maxWidth: 400,
									margin: 'auto',
									color: 'white',
								}}
							>
								<p>Esperando lectura de código QR...</p>
							</Box>
						</>
					)}
				</div>
			)}
			<Button
				size="large"
				style={{
					fontSize: '22px',
					backgroundColor: 'white',
					width: '90%',
					position: 'absolute',
					bottom: '5.5rem',
					left: '0',
					right: '0',
					margin: 'auto',
					zIndex: 999,
				}}
				onClick={() => {
					if (!isEnterPressed) {
						setOpenModal(AccessModals.ManuallyEntry);
					}
				}}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						setIsEnterPressed(true);
					}
				}}
				onKeyUp={(e) => {
					if (e.key === 'Enter') {
						setIsEnterPressed(false);
					}
				}}
			>
				{t('access:manually_enter')}
			</Button>
			<Button
				size="large"
				style={{
					fontSize: '22px',
					backgroundColor: 'white',
					width: '90%',
					position: 'absolute',
					bottom: '1.5rem',
					left: '0',
					right: '0',
					margin: 'auto',
					zIndex: 999,
				}}
				onClick={() => {
					if (!isEnterPressed) {
						setActiveCamera((prev) => !prev);
					}
				}}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						setIsEnterPressed(true);
					}
				}}
				onKeyUp={(e) => {
					if (e.key === 'Enter') {
						setIsEnterPressed(false);
					}
				}}
			>
				{activeCamera ? 'Utilizar escáner' : 'Utilizar cámara'}
			</Button>
			{/* MODALS */}
			{openModal && modals[openModal]}
		</>
	);
}

export default EntryScanner;
